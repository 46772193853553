<template>
  <div id="app">
    <div id="header" class="flex items-center">
      <div id="logo"><img src="./assets/2022-hebcelt-logo-white.png" height="120" width="auto"></div>
      <div id="title" class="grow"><h1 class="text-3xl font-bold">HCF Order Pickups</h1></div>
      <div class="self-start" v-if="online">Status: ONLINE</div>
      <div class="self-start" v-else>Status: OFFLINE</div>
    </div>
    <div id="main">
      <HCFMain></HCFMain>
    </div>
    <div id="footer">&copy; 2022 - 2024 Hebceltfest build {{build}}</div>
  </div>
</template>

<script>
import HCFMain from './components/HCFMain.vue'
import {version} from './version'

export default {
  name: 'App',
  components: {
    HCFMain
  },
  data() {
    return {
      online: true,
      build: null
    }
  },
  methods: {
    goneOnline: function () {
      this.online = true
    },
    goneOffline: function () {
      this.online = false
    }
  },
  created: function () {
    window.addEventListener('online', this.goneOnline);
    window.addEventListener('offline', this.goneOffline);
    this.build = version
  },
  unmounted: function () {
    window.removeEventListener('online', this.goneOnline);
    window.removeEventListener('offline', this.goneOffline);
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import './scss/main.scss';
</style>