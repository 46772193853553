import IdleJs from 'idle-js';
import store from '../store/';
export default {
  data: function() {
    return {
      idle: null
    }
  },
  mounted: function() {

    this.idle = new IdleJs({
      idle: 10000, // idle time in ms
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: () => { store.commit('setIdle'); }, // callback function to be executed after idle time
      onActive: () => { store.commit('setActive'); }, // callback function to be executed after back form idleness
      //onHide: , // callback function to be executed when window become hidden
      //onShow: , // callback function to be executed when window become visible
      keepTracking: true, // set it to false if you want to be notified only on the first idleness change
      startAtIdle: true // set it to true if you want to start in the idle state
    })

    this.idle.start()
  },
  unmounted: function() {

    this.idle.stop()

  }
}
