<template>
  <div id="hcf-main">
    <transition name="fade">
      <div v-if="status.msg" v-bind:class="status.type" class="msg">{{status.msg}}</div>
    </transition>

    <div class="p-4 border rounded p-4 shadow-xl" v-if="!this.config.remote.token">
      <h2 class="text-2xl font-bold mb-4">Login</h2>
      <form @submit.prevent="login()" id="login">
        <div class="flex bg-white">
          <div class="basis-1/3 p-2">Username</div>
          <div class="p-2"><input class="border border-gray-500 rounded shadow w-72 p-2" autocomplete="username" type="text" v-model="credentials.username" /></div>
        </div>
        <div class="flex bg-white">
          <div class="basis-1/3 p-2">Password</div>
          <div class="p-2"><input class="border border-gray-500 rounded shadow w-72 p-2" autocomplete="current-password" type="password" v-model="credentials.password" /></div>
        </div>
        <button class="px-4 py-2 mx-2 my-8 rounded bg-emerald-800 text-white uppercase font-bold">login</button>
      </form>
    </div>

    <div v-else>

      <div class="p-4 border rounded p-4 shadow-xl" v-if="edit_id">

        <h2 class="text-2xl font-bold mb-4">Pickup on order {{edit_id}}</h2>
        <div class="twickets_warning" v-if="local_records[edit_id].twickets==1">WARNING THIS ORDER IS ASSOCIATED WITH TWICKETS</div>
        <div class="flex bg-white even:bg-gray-200" v-for="field,fieldidx in fields" :key="fieldidx">
          <div class="basis-1/3 p-2">{{field}}</div>
          <div class="p-2" v-if="fieldidx=='offline_pickup'"><input class="border-2 border-gray-800 rounded shadow w-72 p-2" @keyup.enter="update(edit_id)" v-model="offline_pickup"></div>
          <div class="p-2" v-else-if="fieldidx=='notes'"><textarea class="border-2 border-gray-800 rounded shadow w-72 p-2" @keyup.enter="update(edit_id)" v-model="notes"></textarea></div>
          <div class="p-2" v-else>{{local_records[edit_id][fieldidx]}}</div>
        </div>
        <button class="px-4 py-2 mx-2 my-8 rounded bg-emerald-800 text-white uppercase font-bold" @click="update(edit_id)">update</button>
        <button class="px-4 py-2 mx-2 my-8 rounded bg-gray-200 text-black uppercase font-bold" @click="reset()">cancel</button>
      </div>

      <div v-else>

        <div class="flex flex-col gap-2 mb-4 border rounded p-4 shadow-xl">

          <h2 class="font-bold basis-full grow text-2xl">Search</h2>

          <div class="flex flex-row">
            <label class="flex-none basis-1/4 lg:basis-60">name:</label>
            <input class="basis-3/4 border rounded lg:basis-60" type="text" v-model.lazy="keyword">
          </div>

          <div class="flex flex-row">
            <label class="flex-none basis-1/4 lg:basis-60">email:</label>
            <input class="basis-3/4 border rounded lg:basis-60" type="text" v-model.lazy="email">
          </div>

          <div class="flex flex-row">
            <label class="flex-none basis-1/4 lg:basis-60">address:</label>
            <input class="basis-3/4 border rounded lg:basis-60" type="text" v-model.lazy="address">
          </div>

          <div class="flex flex-row">
            <label class="flex-none basis-1/4 lg:basis-60">postcode:</label>
            <input class="basis-3/4 border rounded lg:basis-60" type="text" v-model.lazy="postcode">
          </div>

          <div class="flex flex-row">
            <label class="flex-none basis-1/4 lg:basis-60">order id:</label>
            <input class="basis-3/4 border rounded lg:basis-60" type="text" v-model.lazy="id">
          </div>

          <fieldset class="flex flex-row">
            <div class="flex-none basis-1/4 lg:basis-60">show all (slow):</div>
            <div class="basis-3/4 lg:basis-60">
              <label class="mr-2">Yes<input class="ml-2" type="radio" value="1" v-model="config.showall"></label>
              <label class="mr-2">No<input class="ml-2" type="radio" value="0" v-model="config.showall"></label>
            </div>
          </fieldset>

        </div>

        <hr>

        <h3 class="font-bold text-2xl my-4 text-center" v-if="filteredItems">Showing {{ filteredItems.length || 'all' }} records of {{Object.keys(local_records).length}}</h3>
        <h3 class="font-bold text-2xl my-4 text-center" v-else>{{Object.keys(local_records).length}} Records Loaded (not showing)</h3>

        <div class="lg:hidden" v-if="filteredItems">
          <div class="mb-4 border rounded shadow-xl p-4" v-for="record in filteredItems" :key="record.id">
            <div class="flex odd:bg-gray-200"  :class="{ twickets_warning: record.twickets ==1 }" v-for="field in Object.keys(table_fields)" :key="field">
              <div class="basis-1/4 p-1">{{table_fields[field]}}:</div>
              <div class="basis-3/4 p-1">{{record[field]}}</div>
            </div>
            <button class="block w-1/3 mx-auto px-4 py-2 my-2 rounded bg-emerald-800 text-white uppercase font-bold" @click="edit(record['id'])">edit</button>
          </div>
        </div>

        <div class="hidden lg:block">
          <table v-if="filteredItems" class="horizontal">
            <tr>
              <th v-for="field in Object.keys(table_fields)" :key="field">{{table_fields[field]}}</th><th></th>
            </tr>
            <tr v-for="record in filteredItems" :key="record.id">
              <td v-for="field in Object.keys(table_fields)" :key="field" :class="{ twickets_warning: record.twickets ==1 }">{{record[field]}}</td>
              <td><button class="px-4 py-2 rounded bg-emerald-800 text-white uppercase font-bold" @click="edit(record['id'])">edit</button></td>
            </tr>
          </table>
        </div>

      </div>

      <div class="text-right mt-8" v-if="showtools==1">
        <span @click="toggleTools()" class="showtools text-gray-300">[-]</span>
        <h3 class="text-xl font-bold text-center text-gray-600 mb-4">Advanced Tools</h3>
        <div class="flex flex-row gap-2 justify-center">
          <button class="px-4 py-2 rounded bg-blue-800 text-white uppercase font-bold" @click="slurp()">UPDATE FROM LIVE</button>
          <button class="px-4 py-2 rounded bg-blue-800 text-white uppercase font-bold" @click="spit()">SYNC PICKUPS TO LIVE DATABASE</button>
          <button class="px-4 py-2 rounded bg-blue-800 text-white uppercase font-bold" @click="dropLocalDatabase()">DROP LOCAL DATABASE</button>
          <button class="px-4 py-2 rounded bg-blue-800 text-white uppercase font-bold" @click="saveConfig()" v-if="0">SAVE CONFIG</button>
          <button class="px-4 py-2 rounded bg-blue-800 text-white uppercase font-bold" @click="loadConfig()" v-if="0">LOAD CONFIG</button>
          <button class="px-4 py-2 rounded bg-blue-800 text-white uppercase font-bold" @click="apilogout()" v-if="1">Log out</button>
        </div>
        <div class="text-gray-800 text-center my-4">endpoint {{ config.remote.endpoint }}</div>
      </div>
      <div class="text-right mt-8" v-else><span @click="toggleTools()" class="showtools text-gray-300">[+]</span></div>

    </div>
  </div>
</template>

<script>

import HCFDatabase from '../mixins/HCFDatabase';
import HCFIdle from '../mixins/HCFIdle';

export default {
  name: 'HCFMain',
  data() {
    return {
      keyword: '',
      email: '',
      address: '',
      postcode: '',
      id: '',
      showtools: -1,
      edit_id: null,
      offline_pickup: null,
      notes: null,
      filter_num_req_for_string: 4,
      filter_num_req_for_id: 3,
      table_fields: {
        "id": "id",
        "created_at": "date",
        "desc": "desc",
        "total": "total",
        "paid": "paid",
        "billname":"name",
        "email":"email",
        "phone":"phone",
        "picked_up_by":"pickup",
        "offline_pickup":"offline p/up"
      },
      message: ''
    }
  },
  components: { },
  mixins: [HCFDatabase, HCFIdle],
  computed: {
    isIdle: function() {
      return this.$store.state.isIdle
    },
    filteredItems: function() {
      if(this.config.showall==1) {
        return this.local_records
      }
      if(!(this.keyword.length>this.filter_num_req_for_string) &&
          !(this.email.length>this.filter_num_req_for_string) &&
          !(this.id.length>this.filter_num_req_for_id) &&
          !(this.address.length>this.filter_num_req_for_string) &&
          !(this.postcode.length>this.filter_num_req_for_string)) {
        return null
      }

      let filtered = []

      Object.keys(this.local_records).forEach((key) => {

        let include = true

        if(typeof this.local_records[key].id == 'undefined') {
          return
        }

        let id = this.local_records[key]['id'] + ""

        if(include && this.id.length>this.filter_num_req_for_id) {
          if (!(id.toLowerCase().indexOf(this.id.toLowerCase()) > -1)) {
            include = false
          }
        }

        if(include && this.keyword.length>this.filter_num_req_for_string) {
          if(typeof this.local_records[key].billname == 'undefined') {
            include = false;
          } else if (!(this.local_records[key].billname.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)) {
            include = false
          }
        }

        if(include && this.email.length>this.filter_num_req_for_string) {
          if (!(this.local_records[key].email.toLowerCase().indexOf(this.email.toLowerCase()) > -1)) {
            include = false
          }
        }

        if(include && this.address.length>this.filter_num_req_for_string) {
          if (!(this.local_records[key].billaddr1.toLowerCase().indexOf(this.address.toLowerCase()) > -1) &&
              !(this.local_records[key].billaddr2.toLowerCase().indexOf(this.address.toLowerCase()) > -1) &&
              !(this.local_records[key].billcity.toLowerCase().indexOf(this.address.toLowerCase()) > -1) &&
              !(this.local_records[key].billstate.toLowerCase().indexOf(this.address.toLowerCase()) > -1)
          ) {
            include = false
          }
        }

        if(include && this.postcode.length>this.filter_num_req_for_string) {
          if (!(this.local_records[key].billpostcode.toLowerCase().indexOf(this.postcode.toLowerCase()) > -1)) {
            include = false
          }
        }

        if(include) {
          filtered.push(this.local_records[key])
        }


      },this)

      return filtered
    }
  },
  methods: {
    login: function() {
      this.apilogin()
          .then(() => {})
          .catch((error) => {
            console.log(error)
          })
    },
    edit: function(id) {
      this.edit_id = id
      this.offline_pickup = this.local_records[id].offline_pickup
      this.notes = this.local_records[id].notes
    },
    reset: function() {
      this.edit_id = null
      this.offline_pickup = null
      this.notes = null
    },
    update: function (id) {
      this.updateLocalRecord(id, this.offline_pickup, this.notes).then(() => {
      }).then(() => {
        if (this.$parent.online) {
          this.updateRemoteRecord(id)
        }
      }).catch((error) => {
        console.log(error)
      })
      this.reset()
    },
    toggleTools: function() {
      this.showtools = this.showtools * -1
    }
  },
  mounted: function() {
    setTimeout(() => {
      if(this.$parent.online) {
        this.slurp().then(() => {
          setInterval(() => {
            if (this.$parent.online && this.isIdle) {
              this.spit().then(() => {
                this.slurp().then(() => {
                }).catch((error) => {
                  console.log("slurp failed " + error)
                })
              }).catch((error) => {
                this.log(error.message)
              });
            }
          }, 10000);
        }).catch((error) => {
          console.log("slurp failed " + error)
        })
      }
    }, 500);
  }
};
</script>
