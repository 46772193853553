import bcrypt from 'bcryptjs';
import PouchDB from 'pouchdb'

export default {
  data: function() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      localdb: null,
      local_records: [],
      local_records_raw: [],
      remote_records: {},
      fields: {
        "id": "id",
        "twickets":"twickets",
        "created_at": "date",
        "desc": "description",
        "subtotal": "subtotal",
        "total": "total",
        "paid": "paid",
        "refunded": "refunded",
        "donated": "donated",
        "billname":"name",
        "billaddr1":"address1",
        "billaddr2":"address2",
        "billcity":"town / city",
        "billstate":"county / state",
        "billpostcode":"postcode",
        "billcountry":"country",
        "phone":"phone",
        "email":"email",
        "paymeth":"payment method",
        "picked_up_by":"picked up",
        "offline_pickup":"offline pickup",
        "notes":"notes"
      },
      config: {
        showall: 0,
        remote: {
          endpoint: 'https://www.hebceltfest.com/hcfstore/api/',
          token: null,
          hash: null
        },
        lastslurped: null,
        sharedsecret: 'hW^*`e:{<qqx+D.$LI9CE:yUf\'#<c0rN>+efSQt\'xX&7"w:FnyQ]h<r9H79%Jg-;'
      },
      status: {
        msg: '',
        type: ''
      }
    }
  },
  // watch: {
  //   config: {
  //     deep: true,
  //     handler() {
  //       this.saveConfig()
  //     }
  //   }
  // },
  methods: {
    toggleShow: function() {
      this.config.showall *= -1
    },

    apilogin: function() {

      return new Promise((resolve, reject) => {

        const data = {
          'login': this.credentials.username,
          'password': this.credentials.password
        }

        this.config.remote.token = null
        this.config.remote.hash = null
        this.saveConfig()

        this.axios.post(this.config.remote.endpoint + 'login', data).then((response) => {

          let token = response.data

          let matches = null
          try {
             matches = token.match(/^([0-9]+)\|(.*)$/)
          } catch(error) {
            this.statusError("Login attempt failed. Unexpected response.")
            reject(error)
          }

          const user_id = matches[1]
          this.config.remote.token = matches[2]
          this.config.remote.hash = user_id + "|" + bcrypt.hashSync(this.config.remote.token)
          this.config.remote.hash = user_id + "|" + this.config.remote.token

          this.saveConfig()
          this.statusSuccess("Logged in successfully")
          this.slurp().then(() => {
          }).then(() => {
          }).catch(() => {
          }).finally(() => {
            resolve()
          })
        }).catch((error) => {
          if(error.response.status === 401) {
            console.log("Authentication failed - " + error.response.data)
          } else {
            console.log("There was a problem accessing the remote data - " + error.response.data)
          }
          this.statusError("Login attempt failed")
          reject(error.response.data)
        })

      });
    },

    apilogout: function() {
      this.config.remote.token = null
      this.config.remote.hash = null
      this.saveConfig()
      this.dropLocalDatabase()
      this.statusSuccess("Logged out successfully")
    },

    updateLocalRecord: function(id, offline_pickup, notes) {
      return new Promise((resolve, reject) => {
        let record = this.local_records_raw[id]
        record.offline_pickup = offline_pickup
        record.notes = notes
        record.dirty = true

        this.getLocalConnection().then((localdb) => {
          localdb.put(record).then(() => {
            this.statusSuccess("record " + id + " updated")
            this.loadLocalDatabase().then(() => {
              resolve()
            }).catch((error) => {
              console.log(error)
              reject()
            })
          }).catch((error) => {
            console.log(error)
            reject()
          })
        }).catch((error) => {
          console.log(error)
          reject()
        });
      })

    },
    clearDirty: function(id) {
      return new Promise( (resolve, reject) => {
        let record = this.local_records_raw[id]
        record.dirty = false

        this.getLocalConnection().then((localdb) => {
          localdb.put(record).then(() => {
            this.statusSuccess("record " + id + " updated")
            this.loadLocalDatabase().then(() => {
              resolve()
            }).catch((error) => {
              console.log(error)
              reject()
            })
          }).catch((error) => {
            console.log(error)
            reject()
          })
        }).catch((error) => {
          console.log(error)
          reject()
        });
      })

    },
    updateRemoteRecord: function(id) {
      return new Promise( (resolve, reject) => {
        let record = this.local_records_raw[id]
        //need to do all basket items at once
        this.axios.patch(this.config.remote.endpoint + 'ordersync/' + id, record, {
          headers: {
            Authorization: 'Bearer ' + this.config.remote.hash
          }
        }).then(() => {
          this.statusSuccess("Remote record updated")
          this.clearDirty(id).then(() => {
            resolve()
          }).catch(() => {
            reject()
          })
        }).catch((error) => {
          if(error.response.status === 401) {
            console.log("Authentication failed - " + error.response.data)
            this.config.remote.token = null
            this.config.remote.hash = null
          } else {
            // handle error
            this.statusError(error.response.data)
          }
          reject()
        })
      })
    },
    loadLocalDatabase: function() {
      return new Promise((resolve, reject) => {
        this.getLocalConnection().then((localdb) => {
          localdb.allDocs({include_docs: true, conflicts: true}).then((result) => {
            let cleaned_data = new Object()
            let raw_data = new Object()
            result.rows.forEach((e) => {
              let id = e.id + ""
              raw_data[id] = e.doc
              let line = new Object()
              Object.keys(this.fields).forEach((field) => {
                line[field] = e.doc[field]
              })
              cleaned_data[id] = line
            })
            this.local_records = cleaned_data
            this.local_records_raw = raw_data
            resolve()
          }).catch((error) => {
            const msg = 'Issue getting all docs ' + error
            reject(msg)
          })
        }).catch((error) => {
          const msg = 'Rejecting load local ' + error
          reject(msg)
        });
      })
    },
    dropLocalDatabase: function() {
      this.localdb.destroy().then(() => {
        this.localdb=null
        this.config.lastslurped=null
        this.statusSuccess("Local database dropped")
        this.loadLocalDatabase().then(() => {
        }).catch((error) => {
          console.log(error)
        })
      }).catch(function(error) {
        console.log(error)
      })
    },
    getLocalConnection: function() {
      return new Promise((resolve) => {
        if (this.localdb !== null) {
          resolve(this.localdb)
        }
        this.localdb = new PouchDB('hcfoffline', {revs_limit: 1, size: 50})
        resolve(this.localdb)
      })
    },
    slurp: function() {

      return new Promise((resolve, reject) => {

        this.statusClear()

        //don't attempt if not logged in
        if(typeof this.config == 'undefined' ||
          typeof this.config.remote == 'undefined' ||
          typeof this.config.remote.hash == 'undefined' ||
          typeof this.config.remote.hash != 'string' ||
          this.config.remote.hash.length < 1) {
          reject('Not logged in');
          return false;
        }

        const data = {}
        if(this.config.lastslurped != null) {
          data.lastslurped = this.config.lastslurped
        }

        this.axios.post(this.config.remote.endpoint + 'ordersync', data, {
          headers: {
            Authorization: 'Bearer ' + this.config.remote.hash
          }
        }).then((response) => {
          this.remote_records = response.data
          this.config.lastslurped = Math.floor(Date.now() / 1000) //convert to seconds
          this.statusSuccess("Loaded " + this.remote_records.length + " remote records")

          this.loadLocalDatabase().then(() => {
            const merged_data = this.local_records_raw

            for (const e of Object.values(this.remote_records)) {
              if (typeof merged_data[e.id + ""] !== 'undefined' && typeof merged_data[e.id + ""]._rev == "string") {
                e._rev = merged_data[e.id + ""]._rev
              }
              e._id = e.id + ""
              e.dirty = false
              merged_data[e._id] = e
            }

            const docslist = Object.values(merged_data)

            const jdata = JSON.parse(JSON.stringify(docslist))

            this.getLocalConnection().then((localdb) => {
              localdb.bulkDocs(jdata).then(() => {
                this.loadLocalDatabase().then(() => {
                }).catch((error) => {
                  reject(error)
                })
              }).catch((error) => {
                reject(error)
              })
            }).catch((error) => {
              reject(error)
            })
            resolve()
          }).catch((error) => {
            console.log("There was a problem loading the local data - " + error)
            reject(error)
          })
        }).catch((error) => {
          if(error.response.status === 401) {
            console.log("Authentication failed - " + error.response.data)
            this.config.remote.token = null
            this.config.remote.hash = null
          } else {
            console.log("There was a problem accessing the remote data - " + error.response.data)
          }
          reject(error)
        })
      });
    },
    spit: function() {
      return new Promise((resolve, reject) => {

        let i = 0

        try {
          Object.keys(this.local_records).forEach((e) => {
            if (this.local_records_raw[e].dirty) {
              this.updateRemoteRecord(this.local_records[e].id).then(() => {
                this.statusSuccess("Local data synced to live")
              }).catch((error) => {

                if(error.response.status === 401) {
                  console.log("Authentication failed - " + error.response.data)
                  this.config.remote.token = null
                  this.config.remote.hash = null
                } else {
                  // handle error
                  this.statusError(error)
                }
                reject(error)

              })
              i++
            }
          })
        } catch (error) {
          console.log(error)
          this.statusError("There was a problem syncing with the remote database")
          reject(error)
        }
        this.statusSuccess("Synced " + i + " records")
        resolve()
      })
    },
    loadConfig: function () {
      //this.statusWarning('Loading config')
      this.getLocalConnection().then((localdb) => {
        localdb.get('config').then((doc) => {
          let config = new Object()
          Object.keys(doc).forEach((field) => {
            if (field !== '_id' || field !== '_rev') {
              config[field] = doc[field]
            }
          })
          this.config = config
        }).catch(() => {
          //console.log(error)
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    saveConfig: function () {
      //this.statusWarning('Saving config')
      const data = this.config
      data._id = 'config'
      this.getLocalConnection().then((localdb) => {
        localdb.get('config').then((doc) => {
          data._rev = doc._rev
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          localdb.put(data).then(() => {
          }).catch((error) => {
            console.log(error)
          })
        })
      }).catch((error) => {
        console.log(error)
      });
    },
    statusMsg: function(msg, type="warning") {
      this.status.msg = msg
      this.status.type= type
      setTimeout(() => {
        this.statusClear();
      }, 2000)
    },
    statusError: function(msg) {
      this.statusMsg(msg, 'error')
    },
    statusWarning: function(msg) {
      this.statusMsg(msg, 'warning')
    },
    statusSuccess: function(msg) {
      this.statusMsg(msg, 'success')
    },
    statusClear: function() {
      this.status.msg =''
      this.status.type=''
    }
  },
  beforeMount: function() {
    this.loadConfig()
    this.loadLocalDatabase().then(() => {
    }).catch((error) => {
      console.log("Before mount " + error)
    })
  },
  beforeUnmount: function() {
    //this.saveConfig()
    this.getLocalConnection().then((localdb) => {
      localdb.close()
    }).catch((error) => {
      console.log("Before destroy " + error)
    })
  }
}
