import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      isIdle: false
    }
  },
  getters: {
  },
  mutations: {
    setIdle(state) {
      state.isIdle = true
    },
    setActive(state) {
      state.isIdle = false
    }
  },
  actions: {
  },
  modules: {
  }
})

export default store;